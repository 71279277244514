<template>
  <div class="auth-page">
    <pop-header :title="$t('auth.login')"/>
    <div class="auth-page__container">
      <img src="/images/logo-1.png" alt=""/>
      <div class="line-separator"/>
      <form @submit.prevent="onLogin">
        <div class="form-element">
          <label>{{$t('auth.email')}}</label>
          <input type="email" @input="onInput('email', $event.target.value)" class="form-control" required name="email" autocomplete="new-password" />
        </div>
        <div class="form-element mg-t-15">
          <label>{{$t('auth.password')}}</label>
          <input type="password" @input="onInput('password', $event.target.value)" class="form-control" required name="password" autocomplete="new-password" />
        </div>
        <div class="form-element mg-t-15">
          <input type="checkbox" v-model="remember" />
          <label>{{$t('auth.remember')}}</label>
        </div>
        <div class="mg-t-40">
          <button type="submit" class="btn btn--green blocked">{{$t('auth.sign_in')}}</button>
        <!--  <RouterLink to="/register" class="mg-t-10 btn btn--green-outline blocked">{{$t('auth.register')}}</RouterLink>  -->
        </div>
      </form>
    </div>
    <loading v-if="isLoading"/>
  </div>
</template>

<script>
import Loading from '@/components/loading/Loading'
import PopHeader from '@/components/popHeader/popHeader'
import { Auth, User } from '@/api/api'
import { mapGetters } from 'vuex'
export default {
  name: 'Login',
  components: { Loading, PopHeader },
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      resetEmail: '',
      isLoading: false,
      remember: true
    }
  },
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed'
    })
  },
  updated () {
    console.log('login.vue updated' + this.isLogin())
  },
  mounted () {
  },
  methods: {
    redirect (url) {
      window.location = url
    },
    onInput (key, value) {
      this.form = {
        ...this.form,
        [key]: value
      }
    },
    async onLogin () {
      this.isLoading = true

      try {
        const { data } = await Auth.login(this.form.email, this.form.password, 'd_' + Date.now())

        console.log('input' + this.form.password + ' return: ' + data.password + 'yyy')
        Auth.setToken(data.token)

        const user = await User.me()

        console.log('remember login ' + this.remember)
        this.$store.dispatch('auth/login', {
          token: data.token,
          user: {
            ...user.data
          },
          remember: this.remember
        })
        this.$router.push('/places')
      } catch (e) {
        this.$toast.error(this.$t('auth.login_failed'))
      } finally {
        this.isLoading = false
      }
    },
    isLogin () {
      return window.localStorage.getItem('loginToken') !== null || window.sessionStorage.getItem('loginToken') !== null
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>
